.misuse-view {
  padding: 10rem 2rem 24rem 2rem;
}
.misuse-view .misuse-container {
  margin: 0 auto;
  max-width: 120rem;
  color: #424f87;
  padding: 4rem 7rem;
  background-color: white;
  -webkit-box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.2);
}
.misuse-view .misuse-container .misuse-data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #edf6ff;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}
.misuse-view .misuse-container .misuse-data-row .name {
  padding-right: 2rem;
}
@media screen and (max-width: 576px) {
  .misuse-view .misuse-container {
    padding: 4rem;
  }
}
@media screen and (max-width: 500px) {
  .misuse-view .misuse-container {
    padding: 4rem 2rem;
  }
  .misuse-view .misuse-container .misuse-data-row {
    flex-wrap: wrap;
  }
  .misuse-view .misuse-container .misuse-data-row .name {
    display: block;
    width: 100%;
    padding-right: 0;
  }
  .misuse-view .misuse-container .misuse-data-row .a-button {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-top: 1rem;
  }
}
