.pdf-page-component {
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: 2000;
}
.pdf-page-component .content {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}
