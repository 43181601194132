.element-view-wrapper {
  max-width: 152rem;
  padding: 10rem 0 5rem 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.element-view-wrapper .element {
  max-width: 70rem;
  width: 100%;
  background-color: #edf6ff;
  color: #424f87;
  padding: 4rem 5rem;
  position: relative;
}
.element-view-wrapper .element__main-title {
  font-size: 2.7rem;
  font-weight: 700;
  border-bottom: 0.1rem solid #deeeff;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.element-view-wrapper .element__text-block {
  margin-bottom: 2.5rem;
}
.element-view-wrapper .element__text-block .--use-small-font {
  font-size: 1.6rem;
}
.element-view-wrapper .element__text-block--title {
  word-wrap: break-word;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
.element-view-wrapper .element__text-block--text {
  font-size: 1.4rem;
  line-height: 2.8rem;
  text-align: justify;
}
.element-view-wrapper .element__text-block--image {
  max-width: 100%;
  height: auto;
  margin-top: 2rem;
}
.element-view-wrapper .element__logos--wrapper {
  padding: 3rem;
  margin: 3rem -5rem -4rem;
  background-color: white;
}
.element-view-wrapper .element__logos--wrapper h3 {
  font-size: 2.5rem;
  text-align: center;
  margin-top: 5rem;
}
.element-view-wrapper .element__logos--logo {
  -webkit-box-shadow: 0.5rem 0.5rem 1rem 0.3rem rgba(179, 208, 234, 0.4);
  -moz-box-shadow: 0.5rem 0.5rem 1rem 0.3rem rgba(179, 208, 234, 0.4);
  box-shadow: 0.5rem 0.5rem 1rem 0.3rem rgba(179, 208, 234, 0.4);
  margin: 2rem;
  width: calc(50% - 4rem);
}
.element-view-wrapper .image-container {
  max-width: 70rem;
  text-align: center;
  width: 100%;
}
.element-view-wrapper .image-container .container {
  margin-bottom: 2rem;
  text-align: center;
  cursor: pointer;
}
.element-view-wrapper .image-container .container img {
  max-width: 100%;
  height: auto;
}
.other-elements-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  color: #424f87;
  width: 100%;
  max-width: 100rem;
  margin: 3rem auto 0 auto;
}
.other-elements-container .title {
  width: 100%;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 2rem;
}
.other-elements-container .block {
  width: 48%;
  text-align: right;
  -webkit-box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.other-elements-container .block .text {
  width: 100%;
  font-size: 1.8rem;
  text-align: left;
  padding-bottom: 1.2rem;
  min-height: 7rem;
}
.other-elements-container .block .button {
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  background-color: #424f87;
  border: none;
  color: white;
  padding: 1.25rem 2rem;
  margin-top: 1.5rem;
  cursor: pointer;
}
.other-elements-container .block .button span {
  display: inline-block;
  position: relative;
}
.other-elements-container .block .button .arrow-container {
  padding-left: 1.5rem;
  display: inline-block;
  position: relative;
}
.other-elements-container .block .button .arrow-container::before {
  width: 1.5rem;
  height: 0.4rem;
  top: -0.2rem;
  position: relative;
  right: -0.9rem;
  background-color: white;
  display: inline-block;
  content: " ";
}
.other-elements-container .block .button .arrow-container .arrow {
  position: relative;
  right: 0;
  top: 0.2rem;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}
.other-elements-container .block .button:hover {
  background-color: #2a325e;
}
@media screen and (max-width: 1450px) {
  .element-view-wrapper .image-container {
    padding-left: 2rem;
    max-width: 50%;
  }
  .other-elements-container {
    max-width: auto;
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 991px) {
  .element-view-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .element-view-wrapper .element,
  .element-view-wrapper .image-container {
    max-width: 80%;
  }
  .element-view-wrapper .image-container {
    padding-left: 0;
    margin-top: 8rem;
  }
  .other-elements-container {
    max-width: auto;
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 876px) {
  .element-view-wrapper .element,
  .element-view-wrapper .image-container {
    max-width: 98%;
  }
}
@media screen and (max-width: 576px) {
  .element-view-wrapper .element {
    max-width: 100%;
    padding: 4rem 1.5rem;
  }
  .other-elements-container {
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .other-elements-container .block {
    width: 95%;
    margin-bottom: 1.5rem;
  }
}
