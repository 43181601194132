.image-gallery--wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}
.image-gallery--wrapper .close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 3rem;
  cursor: pointer;
  padding: 1rem 3rem;
}
.image-gallery--wrapper .close-button:hover {
  background-color: rgba(128, 128, 128, 0.8);
}
